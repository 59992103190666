import {
  KnowledgeCenterFAQSliceStateType,
  KnowledgeCenterFAQSliceActionsType,
  loadTalentsKnowledgeCenterFAQs,
  loadManagerKnowledgeCenterFAQs,
  resetKnowledgeCenterFAQs,
} from 'src/store/knowledgeCenterFAQSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams } from 'src/repositories/talents/KnowledgeCenterFAQRepository';

type UseEngagementTimeSheetsType = Pick<KnowledgeCenterFAQSliceStateType, 'meta' | 'faqItems'> &
  KnowledgeCenterFAQSliceActionsType & {
    isKnowledgeCenterFAQLoadFinished: boolean;
  };

const useKnowledgeCenterFAQ = (): UseEngagementTimeSheetsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    faqItems,
    index: { fetchStatus: knowledgeCenterFAQIndexStatus },
  } = useAppSelector(state => state.knowledgeCenterFAQ);

  const isKnowledgeCenterFAQLoadFinished = getFetchStatus(knowledgeCenterFAQIndexStatus).isFinished;

  return {
    meta,
    faqItems,
    isKnowledgeCenterFAQLoadFinished,
    loadTalentsKnowledgeCenterFAQs: (params?: IndexParams) => dispatch(loadTalentsKnowledgeCenterFAQs(params)),
    loadManagerKnowledgeCenterFAQs: (params?: IndexParams) => dispatch(loadManagerKnowledgeCenterFAQs(params)),
    resetKnowledgeCenterFAQs: () => dispatch(resetKnowledgeCenterFAQs()),
  };
};

export default useKnowledgeCenterFAQ;
