const DEFAULT_NUMBER_FORMAT_OPTIONS = {
  currency: 'USD',
  currencyDisplay: 'symbol',
  style: 'currency',
  minimumFractionDigits: 2,
};

export const formatCurrency = (value: number, options: Intl.NumberFormatOptions = {}): string => {
  return new Intl.NumberFormat('en-US', { ...DEFAULT_NUMBER_FORMAT_OPTIONS, ...options }).format(value);
};
