import { Alert, IconButton, Typography } from '@mui/material';
import { head } from 'ramda';
import { useState } from 'react';

import useModals from 'src/hooks/useModals';
import WorkExperience from 'src/types/resources/WorkExperience';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import useWorkExperiences from 'src/hooks/talents/useWorkExperiences';
import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import * as WorkExperiencePresenter from 'src/presenters/WorkExperiencePresenter';
import { ReactComponent as EditIcon } from 'src/assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import { convertFromHtmlFormat } from 'src/utils/textEditor';
import TextEditor from 'src/components/TextEditor';
import Box from 'src/components/Box';

import styles from './styles';

type ItemProps = {
  workExperience: WorkExperience;
};

const Item: React.FC<ItemProps> = props => {
  const { workExperience } = props;
  const { id, position, responsibilities } = workExperience;
  const workPeriodWithOrganizationName = WorkExperiencePresenter.workPeriodWithOrganizationName(workExperience);
  const { showModal } = useModals();
  const [errors, setErrors] = useState<string>(null);
  const { loadWorkExperiences, deleteWorkExperience } = useWorkExperiences();

  const handleDelete = async () => {
    try {
      await deleteWorkExperience({ id });
      loadWorkExperiences();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setErrors(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const convertedResponsibilities = convertFromHtmlFormat(responsibilities);

  return (
    <Box sx={styles.wrapper}>
      {errors && <Alert severity="error">{errors}</Alert>}
      <Box sx={styles.description}>
        <Typography variant="h5">{position}</Typography>
        <Typography variant="body1">{workPeriodWithOrganizationName}</Typography>
        <Box sx={styles.textEditorWrapper}>
          <TextEditor value={convertedResponsibilities} readOnly />
        </Box>
      </Box>
      <Box sx={styles.buttons}>
        <IconButton
          sx={styles.button}
          onClick={() =>
            showModal({
              type: ModalType.TalentWorkExperienceForm,
              modalLayout: ModalLayout.talentModals,
              props: { workExperience },
            })
          }
        >
          <EditIcon />
        </IconButton>
        <IconButton sx={styles.button} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Item;
