import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import ReactGA from 'react-ga4';
import { Suspense, useEffect, useState } from 'react';

import useUsers from 'src/hooks/useUsers';
import useSettings from 'src/hooks/useSettings';
import Router from 'src/components/Router';
import Loader from 'src/components/Loader';
import { getGoogleAnalyticsMeasurementID } from 'src/config/googleAnalytics';
import { initializePendoInstance, updatePendoInstance } from 'src/utils/pendo';
import UsersPresenter from 'src/presenters/UsersPresenter';
import OrganizationPresenter from 'src/presenters/OrganizationPresenter';

const App: React.FC = () => {
  const [isInitialLoadFinished, setIsInitialLoadFinished] = useState<boolean>(false);
  const { currentUser, loadCurrentUser, isLoggedOutOfTrackedViaPendoUserBefore } = useUsers();
  const { getSettings } = useSettings();

  const initLogRocketIdentify = async () => {
    try {
      const userId: string = process.env.REACT_APP_ENV + currentUser.id;

      LogRocket.identify(userId.toString(), {
        userId,
        name: currentUser.fullName,
        email: currentUser.email,
        role: currentUser.role,
        env: process.env.REACT_APP_ENV,
      });
    } catch (error) {
      console.error('Error identify LogRocket', error);
    }
  };
  const launchPendo = () => {
    const isTrackedViaPendoOrganization = OrganizationPresenter.isTrackedViaPendo(currentUser.organization);
    const isTrackedViaPendoUser = UsersPresenter.isTrackedViaPendo(currentUser);
    const metaData: pendo.InitOptions = {
      visitor: {
        id: currentUser.id,
        first_name: currentUser.firstName,
        last_name: currentUser.lastName,
        username: currentUser.username,
        email: currentUser.email,
        role: currentUser.role,
        data_joined: currentUser.dateJoined,
        last_login: currentUser.lastLogin,
        is_active: currentUser.isActive,
        is_deactivated: currentUser.isDeactivated,
        is_twilio_subscriber: currentUser.isTwilioSubscriber,
      },
      ...(isTrackedViaPendoOrganization && {
        account: {
          id: currentUser.organization.id,
          name: currentUser.organization.name,
          created_date: currentUser.organization.createdDate,
          rate: currentUser.organization.rate,
          city: currentUser.organization.city,
          state: currentUser.organization.state,
          is_active: currentUser.organization.isActive,
        },
      }),
    };
    if (isTrackedViaPendoUser) {
      if (isLoggedOutOfTrackedViaPendoUserBefore) {
        updatePendoInstance(metaData);
      } else {
        initializePendoInstance(metaData);
      }
    }
  };

  useEffect(() => {
    try {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_TOKEN, {
        dom: { inputSanitizer: true },
      });
      setupLogRocketReact(LogRocket);
    } catch (error) {
      console.error('Error init LogRocket', error);
    }
    if (currentUser) {
      initLogRocketIdentify();
      launchPendo();
    }
  }, [currentUser?.id]);

  useEffect(() => {
    try {
      const googleAnalyticsMeasurementID = getGoogleAnalyticsMeasurementID();
      ReactGA.initialize(googleAnalyticsMeasurementID);
    } catch (error) {
      console.error('Error init Google Analytics', error);
    }
  }, []);

  useEffect(() => {
    const initialLoad = async () => {
      try {
        await loadCurrentUser().unwrap();
      } catch (e) {
        console.error('Unauthenticated user');
      } finally {
        setIsInitialLoadFinished(true);
      }
      getSettings({ name: 'talent_usage_fee' });
    };

    initialLoad();
  }, []);

  if (!isInitialLoadFinished) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      <Router />
    </Suspense>
  );
};

export default App;
