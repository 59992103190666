import { useState } from 'react';
import { Button, CircularProgress, Typography, Divider, Alert } from '@mui/material';
import { head, isNil } from 'ramda';

import { ReactComponent as CirclePlusIcon } from 'src/assets/images/circlePlusIcon.svg';
import AvailabilityType from 'src/types/resources/Availability';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import useAvailabilities from 'src/hooks/talents/useAvailabilities';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';

import Entry from './components/Entry';
import EditableBox from './components/EditableBox';
import styles from './styles';

type AvailabilityProps = {
  onCreate?: () => void;
  onEdit?: (id: number) => void;
  onClose?: () => void;
};

const Availability: React.FC<AvailabilityProps> = props => {
  const { onCreate = null, onEdit = null } = props;
  const [errors, setErrors] = useState<string | null>(null);
  const { availabilities, isLoadAvailabilitiesFinished, loadAvailabilities, deleteAvailability } = useAvailabilities();
  const { showModal, hideModal } = useModals();

  const handleAvailabilityDelete = (availability: AvailabilityType) => async () => {
    try {
      await deleteAvailability({ id: availability.id });
      loadAvailabilities();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setErrors(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const handleAvailabilityEdit = (availability: AvailabilityType) => () => {
    if (!isNil(onEdit)) {
      onEdit(availability.id);
    } else {
      showModal({
        type: ModalType.TalentAvailabilityForm,
        modalLayout: ModalLayout.talentModals,
        props: { availability },
      });
    }
  };
  const handleAvailabilityCreate = () => {
    if (!isNil(onCreate)) {
      onCreate();
    } else {
      showModal({
        type: ModalType.TalentAvailabilityForm,
        modalLayout: ModalLayout.talentModals,
      });
    }
  };

  const handleClose = () => {
    const { onClose } = props;
    hideModal();
    if (onClose) {
      onClose();
    }
  };

  if (!isLoadAvailabilitiesFinished) {
    return (
      <Box sx={styles.box}>
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  return (
    <Box sx={styles.box}>
      {errors && <Alert severity="error">{errors}</Alert>}
      <Box sx={styles.header}>
        <Typography variant="h2" sx={styles.heading} component="h2">
          Availability
        </Typography>
        <Divider />
      </Box>
      <Box sx={styles.body}>
        <Box sx={styles.availabilityList}>
          {availabilities.map(availability => (
            <EditableBox
              key={availability.id}
              handleEdit={handleAvailabilityEdit(availability)}
              handleDelete={handleAvailabilityDelete(availability)}
            >
              <Entry key={availability.id} availability={availability} />
            </EditableBox>
          ))}
        </Box>

        <Button
          size="large"
          color="success"
          variant="text"
          sx={styles.addButton}
          startIcon={<CirclePlusIcon className="plusIcon" />}
          onClick={handleAvailabilityCreate}
        >
          Add Availability
        </Button>
      </Box>
      <Box sx={styles.footer}>
        <Button variant="outlined" sx={styles.button} onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default Availability;
