import { ReactNode } from 'react';
import { Button } from '@mui/material';

import { ReactComponent as EditIcon } from 'src/assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import Box from 'src/components/Box';

import styles from './styles';

type EditableBoxProps = {
  children?: ReactNode;
  handleEdit: () => void;
  handleDelete: () => void;
};

const EditableBox: React.FC<EditableBoxProps> = props => {
  const { children, handleEdit, handleDelete } = props;

  return (
    <Box sx={styles.editableBox}>
      <Box sx={styles.editableBoxMain} className="editableBoxMain">
        {children}
      </Box>
      <Box sx={styles.editableBoxAside} className="editableBoxAside">
        <Button variant="outlined" className="actionButton" onClick={handleEdit}>
          <EditIcon />
        </Button>
        <Button variant="outlined" className="actionButton" onClick={handleDelete}>
          <DeleteIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default EditableBox;
