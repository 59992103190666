import { useEffect } from 'react';
import { Button, Typography } from '@mui/material';

import { useModals, useRouter } from 'src/hooks';
import appRoutes from 'src/routes/appRoutes';
import useWorkExperiences from 'src/hooks/talents/useWorkExperiences';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg';
import Box from 'src/components/Box';
import Loader from 'src/components/Loader';

import Item from './components/Item';
import styles from './styles';

interface WorkExperienceProps {
  onClose?: () => void;
}

const WorkExperience: React.FC<WorkExperienceProps> = props => {
  const { workExperiences, loadWorkExperiences, isWorkExperiencesLoadFinished } = useWorkExperiences();
  const { showModal, hideModal } = useModals();
  const { pathname } = useRouter();

  const handleClose = () => {
    const { onClose } = props;
    hideModal();
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (workExperiences.length === 0 && pathname === appRoutes.talent.workExperience()) {
      loadWorkExperiences();
    }
  }, []);

  if (!isWorkExperiencesLoadFinished) {
    return <Loader />;
  }

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h2" sx={styles.title}>
        Work Experience
      </Typography>
      <Box sx={styles.list}>
        {workExperiences.map(workExperience => (
          <Item key={workExperience.id} workExperience={workExperience} />
        ))}
      </Box>
      <Button
        variant="text"
        startIcon={<PlusIcon />}
        onClick={() => showModal({ type: ModalType.TalentWorkExperienceForm, modalLayout: ModalLayout.talentModals })}
        sx={styles.addButton}
      >
        Add Work Experience
      </Button>
      <Box sx={styles.buttons}>
        <Button variant="outlined" onClick={handleClose} sx={styles.button}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default WorkExperience;
