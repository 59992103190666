import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const useScreen = () => {
  const theme = useTheme();
  const { fablet, tablet, desktop, largeScreen, sm } = theme.breakpoints.values;

  const isMobile = useMediaQuery(theme.breakpoints.down(sm));
  const isTablet = useMediaQuery(theme.breakpoints.between(tablet, desktop));
  const isDesktop = useMediaQuery(theme.breakpoints.between(desktop, largeScreen));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(largeScreen));
  const talentProfileSidebarBreakpoint = useMediaQuery(theme.breakpoints.up(1120));
  const burgerHeaderMenuBreakpoint = useMediaQuery(theme.breakpoints.down(977));

  const lessThanLargeScreen = useMediaQuery(theme.breakpoints.down(largeScreen));
  const lessThanDesktop = useMediaQuery(theme.breakpoints.down(desktop));
  const moreThanDesktop = useMediaQuery(theme.breakpoints.up(desktop));
  const lessThanFablet = useMediaQuery(theme.breakpoints.down(fablet));
  const lessThanTablet = useMediaQuery(theme.breakpoints.down(tablet));

  const lessThanMd = useMediaQuery(theme.breakpoints.down('md'));

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeScreen,
    lessThanDesktop,
    moreThanDesktop,
    lessThanFablet,
    lessThanTablet,
    lessThanLargeScreen,
    talentProfileSidebarBreakpoint,
    burgerHeaderMenuBreakpoint,
    lessThanMd,
  };
};

export default useScreen;
