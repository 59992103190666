import { useEffect } from 'react';
import { Button, Typography } from '@mui/material';

import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { useModals, useRouter } from 'src/hooks';
import appRoutes from 'src/routes/appRoutes';
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg';
import useExternalLinks from 'src/hooks/talents/useExternalLinks';
import Box from 'src/components/Box';
import Loader from 'src/components/Loader';

import Item from './components/Item';
import styles from './styles';

interface ExternalLinksProps {
  onClose?: () => void;
}

const ExternalLinks: React.FC<ExternalLinksProps> = props => {
  const { showModal, hideModal } = useModals();
  const { externalLinks, loadExternalLinks, isExternalLinksLoadFinished } = useExternalLinks();
  const { pathname } = useRouter();

  const handleClose = () => {
    const { onClose } = props;
    hideModal();
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (externalLinks.length === 0 && pathname === appRoutes.talent.externalLinks()) {
      loadExternalLinks();
    }
  }, []);

  if (!isExternalLinksLoadFinished) {
    return <Loader />;
  }

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h2" sx={styles.title}>
        External Links
      </Typography>
      <Box sx={styles.list}>
        {externalLinks.map(externalLink => (
          <Item key={externalLink.id} externalLink={externalLink} />
        ))}
      </Box>
      <Button
        variant="text"
        startIcon={<PlusIcon />}
        onClick={() => showModal({ type: ModalType.TalentExternalLinksForm, modalLayout: ModalLayout.talentModals })}
        sx={styles.addButton}
      >
        Add Link
      </Button>
      <Box sx={styles.buttons}>
        <Button variant="outlined" onClick={handleClose} sx={styles.button}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default ExternalLinks;
