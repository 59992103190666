import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { head, isEmpty, isNil } from 'ramda';
import { useEffect, useState } from 'react';

import { ReactComponent as EmailIcon } from 'src/assets/icons/emailIcon.svg';
import { ReactComponent as PhoneIcon } from 'src/assets/icons/phoneIcon.svg';
import { ReactComponent as SelectArrow } from 'src/assets/icons/selectArrow.svg';
import { ContactType, ContactTypeLabel } from 'src/enums/ContactType';
import { ReassignAction } from 'src/enums/OrganizationUser';
import {
  attributesToSubmit,
  CreateOrganizationUserFormData,
  initialValues,
  validationSchema,
} from 'src/forms/createOrganizationUser';
import UserRolePresenter from 'src/presenters/UserRolePresenter';
import { LoadUsersCountParams } from 'src/repositories/superAdmin/OrganizationsRepository';
import { IndexParams, ReassignParams, UpdateParams } from 'src/repositories/superAdmin/OrganizationsUsersRepository';
import { Department } from 'src/types/resources/Department';
import { OrganizationUser } from 'src/types/resources/Organization';
import { ListResponse } from 'src/types/utils';
import { isCustomContactType } from 'src/utils/contactType';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { createUsersForRadioGroup, RadioItem } from 'src/utils/users';
import useLoading from 'src/hooks/useLoading';
import useModals from 'src/hooks/useModals';
import Box from 'src/components/Box';
import PhoneNumberField from 'src/components/PhoneNumberField';

import AssignedDepartmentField from './components/AssignedDepartmentField';
import DeletableTextField from './components/DeletableTextField';
import RoleRadioControl from './components/RoleRadioControl';
import UserReassignmentRadioControl from './components/UserReassignmentRadioControl';
import styles from './styles';

type FormProps = {
  user?: OrganizationUser;
  organizationId: number;
  departments: Department[];
  isDepartmentsLoadFinished: boolean;
  createUser: (params: CreateOrganizationUserFormData) => { unwrap: () => void };
  updateUser: (params: UpdateParams) => { unwrap: () => void };
  reassignUser: (params: ReassignParams) => { unwrap: () => void };
  loadUsers: (params: IndexParams) => void;
  loadUsersForReassign: (params: IndexParams) => Promise<ListResponse<OrganizationUser>>;
  loadDepartments: (params: IndexParams) => { unwrap: () => void };
  loadOrganizationUsersCount?: (params: LoadUsersCountParams) => { unwrap: () => void };
  searchParams?: IndexParams;
};

const Form: React.FC<FormProps> = props => {
  const {
    organizationId,
    user,
    departments,
    isDepartmentsLoadFinished,
    createUser,
    reassignUser,
    updateUser,
    loadUsers,
    loadUsersForReassign,
    loadDepartments,
    loadOrganizationUsersCount,
    searchParams,
  } = props;

  const [formError, setFormError] = useState<string | null>(null);
  const [usersForReassignPermissions, setUsersForReassignPermissions] = useState<RadioItem[]>([]);
  const { hideModal } = useModals();

  const {
    funcWithLoading: loadUsersForReassignEngagementPermissions,
    isPending: isLoadUsersForReassignEngagementPermissionsPending,
  } = useLoading(() => loadUsersForReassign({ forAction: ReassignAction.engagements, managerId: user.id }));

  const {
    funcWithLoading: loadUsersForReassignAllPermissions,
    isPending: isLoadUsersForReassignAllPermissionsPending,
  } = useLoading(() => loadUsersForReassign({ forAction: ReassignAction.full, managerId: user.id }));

  const initialFormValues = { ...initialValues(user), organizationId };

  const isAddForm = isNil(user);
  const formTitle = isAddForm ? 'Add User/Contact' : 'Edit details';
  const submitButtonText = isAddForm ? 'Add' : 'Save';
  const emailHelperText = isAddForm
    ? 'They will receive an invitation to this email to join your Organization'
    : 'This is their login email';

  const getUsersForReassignPermissions = async (action: ReassignAction): Promise<RadioItem[]> => {
    let response;
    if (action === ReassignAction.engagements) {
      response = await loadUsersForReassignEngagementPermissions();
    }
    if (action === ReassignAction.full) {
      response = await loadUsersForReassignAllPermissions();
    }
    const radioControlItems = createUsersForRadioGroup(
      response.results.filter(responseUser => responseUser.id !== user.id),
    );
    setUsersForReassignPermissions(radioControlItems);

    return radioControlItems;
  };

  const handleSubmit = async (
    formData: CreateOrganizationUserFormData,
    { setErrors }: FormikHelpers<CreateOrganizationUserFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      if (isAddForm) {
        await createUser(params).unwrap();
      } else {
        const { reassignedUserId } = formData;
        if (!isNil(reassignedUserId)) {
          await reassignUser({
            id: user.id,
            params: { managerId: Number(reassignedUserId), isEngagementsOnly: formData.isEngagementsOnly },
          }).unwrap();
        }
        await updateUser({ id: user.id, params }).unwrap();
      }
      loadOrganizationUsersCount({ id: organizationId });
      loadUsers({ ...searchParams, organizationId });
      hideModal();
    } catch (error) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        setFormError(head(error.response.data.errors));
        setErrors(parseToFormikErrors(errors));
      }
    }
  };

  const {
    values,
    errors,
    handleSubmit: handleFormikSubmit,
    touched,
    handleBlur,
    handleChange,
    isSubmitting,
    setFieldValue,
    setValues,
  } = useFormik<CreateOrganizationUserFormData>({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const isManagerRole = UserRolePresenter.isManagerRole(values.role);
  const isContactRole = UserRolePresenter.isContactRole(values.role);
  const isOrganizationUserRole = UserRolePresenter.isOrganizationRole(values.role);

  const isCustomContact = isCustomContactType(values.contactType);

  const hasPermissionSendEngagementDisabled =
    !isAddForm &&
    ((!initialFormValues.isDeactivated && values.isDeactivated) || isLoadUsersForReassignEngagementPermissionsPending);

  const isManagerDepartmentChanged =
    isManagerRole && !isNil(initialFormValues.departmentId) && values.departmentId !== initialFormValues.departmentId;

  const isAssignedDepartmentDisabled = isManagerRole && !initialFormValues.isDeactivated && values.isDeactivated;

  const isUsersForReassignDepartmentVisible =
    isManagerDepartmentChanged && !isAssignedDepartmentDisabled && !isEmpty(usersForReassignPermissions);

  const isUsersForReassignSendEngagementVisible =
    !hasPermissionSendEngagementDisabled &&
    !values.hasPermissionSendEngagement &&
    initialFormValues.hasPermissionSendEngagement &&
    !isEmpty(usersForReassignPermissions);

  const isUsersForReassignDeactivateVisible =
    !isAddForm &&
    isOrganizationUserRole &&
    values.isDeactivated &&
    !initialFormValues.isDeactivated &&
    !isEmpty(usersForReassignPermissions);

  const handleArrayFieldAdd = (field: string) => () => {
    setFieldValue(field, [...values[field], '']);
  };

  const handleArrayFieldRemove = (field: string, index: number) => () => {
    const currentFieldValues: string[] = values[field];
    setFieldValue(
      field,
      currentFieldValues.filter((value, currentIndex) => currentIndex !== index),
    );
  };

  const handleContactTypeChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setFieldValue('customContactType', '');
    handleChange(event);
  };

  const handleDepartmentChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (isManagerRole) {
      const firstReassignedUser = head(usersForReassignPermissions);
      const isDepartmentChanged = Number(event.target.value) !== initialFormValues.departmentId;
      const isSetReassignedUserId =
        !isNil(initialFormValues.departmentId) && !isNil(firstReassignedUser) && isDepartmentChanged;

      setFieldValue('reassignedUserId', isSetReassignedUserId ? firstReassignedUser.value : null);
    }

    handleChange(event);
  };

  const handleUserDeactivate = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (checked) {
      const responseUsersForReassignPermissions = await getUsersForReassignPermissions(ReassignAction.full);
      const firstUserForReassign = head(responseUsersForReassignPermissions);
      if (!isNil(firstUserForReassign)) {
        setFieldValue('reassignedUserId', firstUserForReassign.value);
      }
      setFieldValue('departmentId', initialFormValues.departmentId || null);
      setFieldValue('hasPermissionSendEngagement', initialFormValues.hasPermissionSendEngagement);
      setFieldValue('isEngagementsOnly', false);
    } else {
      setFieldValue('reassignedUserId', null);
      setFieldValue('isEngagementsOnly', initialFormValues.isEngagementsOnly);
    }

    setFieldValue('isDeactivated', checked);
  };

  const handleHasPermissionSendEngagementChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setFieldValue('hasPermissionSendEngagement', checked);
    if (!isAddForm && initialFormValues.hasPermissionSendEngagement && !checked) {
      const responseUsersForReassignPermissions = await getUsersForReassignPermissions(ReassignAction.engagements);
      if (!isEmpty(responseUsersForReassignPermissions)) {
        const firstUserForReassign = head(responseUsersForReassignPermissions);
        if (!isNil(firstUserForReassign)) {
          setFieldValue('reassignedUserId', firstUserForReassign.value);
          setFieldValue('isEngagementsOnly', true);
        }
      }
    } else {
      setFieldValue('reassignedUserId', null);
      setFieldValue('isEngagementsOnly', false);
    }
  };

  const renderUsersForReassignPermissions = (radioItems: RadioItem[], title?: string) => {
    return (
      <UserReassignmentRadioControl
        title={title}
        row
        name="reassignedUserId"
        value={values.reassignedUserId}
        onChange={handleChange}
        sx={styles.radioGroup}
        radioItems={radioItems}
      />
    );
  };

  const renderAdditionalFields = (fields: string[], fieldName: string, fieldLabel: string) => {
    return fields.map((fieldValue, index) => {
      const label = `${fieldLabel} ${index + 2}`;
      const isPhoneField = fieldName === 'additionalPhoneList';
      const Field = isPhoneField ? PhoneNumberField : TextField;
      const conditionalProps = { ...(isPhoneField && { isCaptionVisible: false }) };
      return (
        <DeletableTextField key={label} onDelete={handleArrayFieldRemove(fieldName, index)}>
          <Field
            id={fieldName}
            name={`${fieldName}[${index}]`}
            label={label}
            value={values[fieldName][index]}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched[fieldName]?.[index] && Boolean(errors[fieldName]?.[index])}
            helperText={touched[fieldName]?.[index] && errors[fieldName]?.[index]}
            {...conditionalProps}
          />
        </DeletableTextField>
      );
    });
  };

  useEffect(() => {
    loadDepartments({ organizationId });
  }, []);

  useEffect(() => {
    if (!isAddForm) {
      getUsersForReassignPermissions(ReassignAction.full);
    }
  }, [isAddForm]);

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <form noValidate onSubmit={handleFormikSubmit}>
        <Box sx={styles.wrapper}>
          <Typography variant="h3" sx={styles.heading}>
            {formTitle}
          </Typography>
          <Box sx={styles.row}>
            <TextField
              id="firstName"
              label="First Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Box>
          <Box sx={styles.row}>
            <TextField
              id="lastName"
              label="Last Name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Box>
          <Box sx={styles.row}>
            <RoleRadioControl
              row
              name="role"
              value={values.role}
              formValues={values}
              initialFormValues={initialFormValues}
              setFormValues={setValues}
              sx={styles.radioGroup}
              userRole={user?.role}
              isAddForm={isAddForm}
              error={errors.role}
            />
          </Box>
          {(isManagerRole || isContactRole) && (
            <Box sx={styles.row}>
              <AssignedDepartmentField
                value={values.departmentId}
                onChange={handleDepartmentChange}
                onBlur={handleBlur}
                error={touched.departmentId && Boolean(errors.departmentId)}
                helperText={touched.departmentId && errors.departmentId}
                sx={styles.assignedDepartment}
                departments={departments}
                isContactRole={isContactRole}
                isDepartmentsLoadFinished={isDepartmentsLoadFinished}
                isAssignedDepartmentDisabled={isAssignedDepartmentDisabled}
              />
              {!isAddForm && (
                <>
                  <FormHelperText
                    sx={styles.helperText}
                    error={
                      !isAssignedDepartmentDisabled &&
                      isManagerDepartmentChanged &&
                      !isEmpty(usersForReassignPermissions)
                    }
                  >
                    To change the Department, select another User to inherit the permissions (Groups, Talents,
                    Engagements, Time Tracking).
                  </FormHelperText>
                  {isUsersForReassignDepartmentVisible &&
                    renderUsersForReassignPermissions(usersForReassignPermissions)}
                </>
              )}
            </Box>
          )}
          {isContactRole && (
            <Box sx={styles.row}>
              <TextField
                id="contactType"
                name="contactType"
                label="Contact Type"
                onBlur={handleBlur}
                onChange={handleContactTypeChange}
                value={values.contactType}
                error={touched.contactType && Boolean(errors.contactType)}
                helperText={touched.contactType && errors.contactType}
                SelectProps={{ IconComponent: SelectArrow }}
                select
              >
                {Object.entries(ContactTypeLabel).map(([contact, contactLabel]) => (
                  <MenuItem key={contact} value={ContactType[contact]}>
                    {contactLabel}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          )}
          {isContactRole && isCustomContact && (
            <Box sx={styles.row}>
              <TextField
                id="customContactType"
                name="customContactType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.customContactType}
                error={touched.customContactType && Boolean(errors.customContactType)}
                helperText={touched.customContactType && errors.customContactType}
                placeholder="Type the name"
              />
            </Box>
          )}
          <Box sx={styles.row}>
            <TextField
              id="title"
              label="Title"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
              error={touched.title && Boolean(errors.title)}
              helperText={touched.title && errors.title}
            />
          </Box>
          <Box sx={styles.additionalRow}>
            <TextField
              id="email"
              label="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={touched.email && Boolean(errors.email)}
              helperText={touched.email && errors.email}
            />
            {!isContactRole && <FormHelperText sx={styles.helperText}>{emailHelperText}</FormHelperText>}
            {renderAdditionalFields(values.additionalEmailList, 'additionalEmailList', 'Email')}
            <Button size="small" startIcon={<EmailIcon />} onClick={handleArrayFieldAdd('additionalEmailList')}>
              Add Email
            </Button>
          </Box>
          <Box sx={styles.additionalRow}>
            <PhoneNumberField
              id="phone"
              label="Phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              isCaptionVisible={false}
              error={touched.phone && Boolean(errors.phone)}
              helperText={touched.phone && errors.phone}
            />
            {renderAdditionalFields(values.additionalPhoneList, 'additionalPhoneList', 'Phone')}
            <Button size="small" startIcon={<PhoneIcon />} onClick={handleArrayFieldAdd('additionalPhoneList')}>
              Add Phone
            </Button>
          </Box>
          {isManagerRole && (
            <>
              <Box sx={styles.row}>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">User Permissions</FormLabel>
                  <FormGroup sx={styles.checkboxGroup} row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="hasPermissionViewRate"
                          onChange={handleChange}
                          checked={values.hasPermissionViewRate}
                        />
                      }
                      label="View Rate"
                    />
                    <Stack direction="row" gap="8px" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="hasPermissionSendEngagement"
                            onChange={handleHasPermissionSendEngagementChange}
                            checked={values.hasPermissionSendEngagement}
                            disabled={hasPermissionSendEngagementDisabled}
                          />
                        }
                        label="Send Engagement"
                      />
                      {isLoadUsersForReassignEngagementPermissionsPending && <CircularProgress size={15} />}
                    </Stack>
                  </FormGroup>
                </FormControl>
              </Box>
              {isUsersForReassignSendEngagementVisible && (
                <Box sx={styles.row}>
                  {renderUsersForReassignPermissions(
                    usersForReassignPermissions,
                    'User who inherits the Engagements and Time Tracking',
                  )}
                </Box>
              )}
            </>
          )}
          {!isAddForm && (
            <>
              <Box sx={styles.row}>
                <Stack direction="row" gap="8px" alignItems="center">
                  <FormControlLabel
                    sx={styles.switch}
                    control={
                      <Switch
                        id="isDeactivated"
                        name="isDeactivated"
                        checked={values.isDeactivated}
                        onChange={handleUserDeactivate}
                        disabled={isLoadUsersForReassignAllPermissionsPending}
                      />
                    }
                    label="Deactivate User"
                  />
                  {isLoadUsersForReassignAllPermissionsPending && <CircularProgress size={15} />}
                </Stack>
              </Box>
              {isUsersForReassignDeactivateVisible && (
                <Box sx={styles.row}>
                  {renderUsersForReassignPermissions(
                    usersForReassignPermissions,
                    'Reassign all groups, active engagements and Time Tracking to',
                  )}
                </Box>
              )}
            </>
          )}
          <Box sx={styles.footer}>
            <Button type="submit" variant="contained" sx={styles.button} disabled={isSubmitting}>
              {submitButtonText}
            </Button>
            <Button variant="outlined" sx={styles.button} onClick={hideModal}>
              Cancel
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default Form;
