import { createContext, useState, useEffect } from 'react';

import { FeatureFlags } from 'src/types/featureFlags';
import { featureFlagsApi } from 'src/repositories/featureFlagsApi';

export const FeatureFlagsContext = createContext<FeatureFlags | null>(null);

const defaultFeatureFlags: FeatureFlags = {
  featureShowOrgComments: false,
  featureShowSuperadminComments: false,
  featureShowAiOrgsideMatching: false,
  featureShowEngagementTerms: false,
  featureShowOrgBrrPricingUpdate: false,
  featureShowOrgPricingStructure: false,
  featureShowModifiedAppForDemoEnv: false,
  featureShowOrgStartRate: false,
  featureUseNewStatusesForApply: false,
  featureUseTalentUsageFee: false,
  featureUseNewTalentProfileRate: false,
  featureUseEngagementExtension: false,
  featureUseTalentProfileVideo: false,
  featureShowNewTalentDashboard: false,
};

let featureFlags: FeatureFlags = defaultFeatureFlags;
export const setFeatureFlags = (flags: FeatureFlags) => {
  featureFlags = { ...featureFlags, ...flags };
};
export const getFeatureFlags = () => {
  return { ...featureFlags };
};

export const FeatureFlagsProvider: React.FunctionComponent = ({ children }) => {
  const [features, setFeatures] = useState<FeatureFlags>(defaultFeatureFlags);

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      const apiResponse = await featureFlagsApi.fetchFeatureFlags();

      const fetchedFeatureFlags = Object.fromEntries(
        Object.entries(apiResponse).map(([featureFlagName, featureFlagValueFromApi]) => [
          featureFlagName,
          featureFlagValueFromApi === 'true' || featureFlagValueFromApi === true,
        ]),
      ) as FeatureFlags;

      setFeatures(prevFeatures => {
        const newFeatureFlags: FeatureFlags = { ...prevFeatures, ...fetchedFeatureFlags };
        setFeatureFlags(newFeatureFlags);
        return newFeatureFlags;
      });
    };

    fetchFeatureFlags();
  }, []);

  return <FeatureFlagsContext.Provider value={features}>{children}</FeatureFlagsContext.Provider>;
};
