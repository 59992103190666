import { useEffect, useMemo, useState } from 'react';
import { Avatar, Button } from '@mui/material';
import { isEmpty } from 'ramda';

import OrganizationUserPresenter from 'src/presenters/OrganizationUserPresenter';
import useOrganizationsUsers from 'src/hooks/manager/useManagerOrganizationUsers';
import useModals from 'src/hooks/useModals';
import useUsers from 'src/hooks/useUsers';
import Box from 'src/components/Box';
import AvatarInfo from 'src/components/AvatarInfo';
import SearchForm from 'src/components/SearchForm';
import SelectableList from 'src/components/SelectableList';

import styles from './styles';

type ManagerSelectProps = {
  isSubmitting: boolean;
  setFieldValue: (field: string, value: unknown, shouldValidate?: boolean) => Promise<unknown>;
  onSubmit: () => void;
};

const ManagerSelect: React.FC<ManagerSelectProps> = props => {
  const { isSubmitting, setFieldValue, onSubmit } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShareDisabled, setIsShareDisabled] = useState<boolean>(true);
  const { hideModal } = useModals();
  const { currentUser } = useUsers();
  const { organizationUsers: users, isOrganizationsUsersLoadFinished, loadOrganizationUsers } = useOrganizationsUsers();

  const organizationUsers = useMemo(
    () => users.filter(user => OrganizationUserPresenter.isOrganizationUser(user) && user.id !== currentUser.id),
    [users],
  );

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSelectSubgroups = () => {
    onSubmit();
  };

  useEffect(() => {
    loadOrganizationUsers({ firstName: isEmpty(searchValue) ? undefined : searchValue });
  }, [searchValue]);

  useEffect(() => {
    if (isOrganizationsUsersLoadFinished) {
      setFieldValue('managers', organizationUsers);
    }
  }, [isOrganizationsUsersLoadFinished]);

  return (
    <>
      <Box sx={styles.body}>
        <SearchForm sx={styles.search} placeholder="Search by manager name" onSearch={handleSearch} />
        <SelectableList
          title="Select Manager"
          isLoading={!isOrganizationsUsersLoadFinished}
          data={organizationUsers}
          onItemSelect={selectedManagers => {
            setFieldValue('managers', selectedManagers);
            setIsShareDisabled(isEmpty(selectedManagers));
          }}
          renderListItem={listItemProps => {
            return (
              <AvatarInfo
                notBold
                avatar={<Avatar variant="rounded" sx={styles.avatar} src={listItemProps.avatarPicture} />}
                primaryText={OrganizationUserPresenter.fullName(listItemProps)}
              />
            );
          }}
        />
      </Box>
      <Box sx={styles.footer}>
        <Button
          variant="contained"
          sx={styles.button}
          onClick={handleSelectSubgroups}
          disabled={isShareDisabled || isSubmitting}
        >
          Share
        </Button>
        <Button variant="outlined" onClick={hideModal} sx={styles.button}>
          Cancel
        </Button>
      </Box>
    </>
  );
};

export default ManagerSelect;
