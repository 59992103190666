import { useFormik } from 'formik';
import { Typography, Button, Alert } from '@mui/material';
import { useState } from 'react';
import { EditorState } from 'draft-js';

import useModals from 'src/hooks/useModals';
import { attributesToSubmit, initialValues, OverviewFormData, validationSchema } from 'src/forms/talents/overview';
import { isAxiosError, isNotFoundError } from 'src/utils/responseErrors';
import TextEditor from 'src/components/TextEditor';
import Box from 'src/components/Box';
import useUsers from 'src/hooks/useUsers';

import styles from './styles';

interface OverviewProps {
  onSave?: () => void;
  onCancel?: () => void;
}

const Overview: React.FC<OverviewProps> = props => {
  const [formError, setFormError] = useState<string | null>(null);
  const { currentUser, partialUpdateUser } = useUsers();
  const { hideModal } = useModals();

  const handleSubmit = async (formData: OverviewFormData) => {
    const { onSave } = props;
    const params = attributesToSubmit(formData);

    try {
      await partialUpdateUser(params).unwrap();
      hideModal();
      if (onSave) {
        onSave();
      }
    } catch (error) {
      if (isAxiosError(error) && isNotFoundError(error)) {
        setFormError(error.response.data.errors.detail);
      }
    }
  };

  const onClickCancel = () => {
    const { onCancel } = props;
    hideModal();
    if (onCancel) {
      onCancel();
    }
  };

  const { values, errors, submitForm, touched, setFieldValue } = useFormik<OverviewFormData>({
    initialValues: initialValues(currentUser.summary),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  const handleWisywigChange = (editorState: EditorState) => {
    setFieldValue('summary', editorState);
  };

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.wrapper}>
        <Typography variant="h2" sx={styles.title}>
          Summary
        </Typography>
        <Box>
          <TextEditor
            placeholder="Write about yourself...."
            value={values.summary}
            onChange={handleWisywigChange}
            error={touched.summary && errors.summary}
            usePredefinedMinHeight
          />
        </Box>

        <Box>
          <Button variant="contained" onClick={submitForm} sx={styles.button}>
            Save
          </Button>
          <Button variant="outlined" onClick={onClickCancel} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Overview;
