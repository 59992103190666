import { useState } from 'react';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as SearchIcon } from 'src/assets/images/searchIcon.svg';
import { DEBOUNCE_WAIT } from 'src/utils/fetcher';
import Box from 'src/components/Box';

import styles from './styles';

export type SearchFormProps = {
  onSearch: (searchValue: string) => void;
  onReset?: () => void;
  placeholder?: string;
  sx?: SxProps<Theme>;
};

const SearchForm: React.FC<SearchFormProps> = props => {
  const { onSearch, placeholder = 'Search', sx = {}, onReset } = props;
  const [value, setValue] = useState<string>('');
  const debouncedOnSearch = useDebouncedCallback(onSearch, DEBOUNCE_WAIT);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const text = event.target.value;
    setValue(text);
    debouncedOnSearch(text);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
  };

  const handleReset = (): void => {
    setValue('');
    onSearch('');
    onReset?.();
  };

  return (
    <Box sx={sx}>
      <form noValidate id="searchForm" onSubmit={handleSubmit} onReset={handleReset}>
        <TextField
          value={value}
          name="searchValue"
          type="search"
          sx={styles.searchField}
          placeholder={placeholder}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </Box>
  );
};

export default SearchForm;
