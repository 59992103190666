import { FormikHelpers, useFormik } from 'formik';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { head, isNil } from 'ramda';

import useExternalLinks from 'src/hooks/talents/useExternalLinks';
import useModals from 'src/hooks/useModals';
import {
  attributesToSubmit,
  initialValues,
  validationSchema,
  ExternalLinkFormData,
} from 'src/forms/talents/externalLink';
import {
  extractResponseErrors,
  isAxiosError,
  isUnprocessedEntityError,
  parseToFormikErrors,
} from 'src/utils/responseErrors';
import { ExternalLink } from 'src/types/resources/ExternalLink';
import Box from 'src/components/Box';

import styles from './styles';

type FormProps = {
  externalLink?: ExternalLink;
};

const Form: React.FC<FormProps> = props => {
  const { externalLink } = props;
  const isAddForm = isNil(externalLink);
  const formTitle = isAddForm ? 'Add External Link' : 'Edit External Link';

  const { hideModal } = useModals();
  const [formError, setFormError] = useState<string>(null);
  const { createExternalLink, updateExternalLink, loadExternalLinks } = useExternalLinks();

  const handleSubmit = async (
    formData: ExternalLinkFormData,
    { setSubmitting, setErrors }: FormikHelpers<ExternalLinkFormData>,
  ) => {
    const params = attributesToSubmit(formData);
    setFormError(null);
    try {
      if (isAddForm) {
        await createExternalLink(params).unwrap();
      } else {
        await updateExternalLink({ id: externalLink.id, params }).unwrap();
      }
      await loadExternalLinks();
      hideModal();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        const errors = extractResponseErrors(error);
        if (error.response.data.errors.nonFieldErrors) {
          setFormError(head(error.response.data.errors.nonFieldErrors));
        }
        setErrors(parseToFormikErrors(errors));
      }
    } finally {
      setSubmitting(false);
    }
  };

  const { values, errors, submitForm, touched, handleChange } = useFormik<ExternalLinkFormData>({
    initialValues: initialValues(externalLink),
    validationSchema,
    onSubmit: handleSubmit,
    validateOnChange: true,
  });

  return (
    <>
      {formError && <Alert severity="error">{formError}</Alert>}
      <Box sx={styles.wrapper}>
        <Typography variant="h3">{formTitle}</Typography>
        <Box sx={styles.row}>
          <TextField
            id="link"
            label="Link"
            type="text"
            onChange={handleChange}
            value={values.link}
            error={touched.link && Boolean(errors.link)}
            helperText={touched.link && errors.link}
          />
        </Box>
        <Box sx={styles.row}>
          <TextField
            id="description"
            label="Description"
            type="text"
            multiline
            onChange={handleChange}
            value={values.description}
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </Box>
        <Box sx={styles.buttons}>
          <Button variant="contained" onClick={submitForm} sx={styles.button}>
            Save
          </Button>
          <Button variant="outlined" onClick={hideModal} sx={styles.button}>
            Cancel
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Form;
