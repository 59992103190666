import { Button, Typography } from '@mui/material';
import { isEmpty } from 'ramda';

import { useModals } from 'src/hooks';
import useTalentSpecializations from 'src/hooks/talents/useSpecializations';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg';
import Box from 'src/components/Box';
import Loader from 'src/components/Loader';

import Item from './components/Item';
import styles from './styles';

interface SpecializationProps {
  onClose?: () => void;
}

const Specialization: React.FC<SpecializationProps> = props => {
  const { specializations, isSpecializationsLoadFinished } = useTalentSpecializations();
  const { showModal, hideModal } = useModals();

  const handleClose = () => {
    const { onClose } = props;
    hideModal();
    if (onClose) {
      onClose();
    }
  };

  if (!isSpecializationsLoadFinished) {
    return <Loader />;
  }

  const addButtonText = 'Add Specialization';

  return (
    <Box sx={styles.wrapper}>
      <Typography variant="h2" sx={styles.title}>
        Specializations & Skills
      </Typography>
      {!isEmpty(specializations) && (
        <Box sx={styles.list}>
          {specializations.map(specialization => (
            <Box key={specialization.id}>
              <Typography sx={styles.itemTitle} variant="h5" component="p">
                Specialization
              </Typography>
              <Item specialization={specialization} />
            </Box>
          ))}
        </Box>
      )}
      <Box sx={styles.addButtonWrapper}>
        <Button
          variant="text"
          endIcon={<PlusIcon />}
          onClick={() => showModal({ type: ModalType.TalentSpecializationForm, modalLayout: ModalLayout.talentModals })}
          sx={styles.addButton}
        >
          {addButtonText}
        </Button>
        <Typography sx={styles.caption} variant="caption">
          Pro Tip: For higher matching success between an organization and you, be sure to enter relevant skills &
          modules for roles you would like to be considered.
        </Typography>
      </Box>
      <Box sx={styles.buttons}>
        <Button variant="outlined" onClick={handleClose} sx={styles.button}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default Specialization;
