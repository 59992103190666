import { UserRole } from 'src/enums/UserRole';
import { OrganizationUser } from 'src/types/resources/Organization';

const OrganizationUserPresenter = {
  fullName: (user: OrganizationUser): string => {
    return `${user.firstName} ${user.lastName}`;
  },
  isOrganizationUser: (user: OrganizationUser): boolean =>
    user.role === UserRole.Manager || user.role === UserRole.OrganizationAdmin,
  isManager: (user: OrganizationUser): boolean => user.role === UserRole.Manager,
  emailList: (user: OrganizationUser): string => {
    const { email, additionalEmailList } = user;
    const emailList = [email, ...additionalEmailList].join('\n');

    return emailList;
  },
};

export default OrganizationUserPresenter;
