import MuiButton from './MuiButton';
import MuiLink from './MuiLink';
import MuiInput from './MuiInput';
import MuiTextField from './MuiTextField';
import MuiInputLabel from './MuiInputLabel';
import MuiFormHelperText from './MuiFormHelperText';
import MuiFormControl from './MuiFormControl';
import MuiDialog from './MuiDialog';
import MuiDialogTitle from './MuiDialogTitle';
import MuiIconButton from './MuiIconButton';
import MuiCheckbox from './MuiCheckbox';
import MuiFormLabel from './MuiFormLabel';
import MuiCalendarPicker from './MuiCalendarPicker';
import MuiTableContainer from './MuiTableContainer';
import MuiTableRow from './MuiTableRow';
import MuiTableCell from './MuiTableCell';
import MuiTableSortLabel from './MuiTableSortLabel';
import MuiTablePagination from './MuiTablePagination';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiTypography from './MuiTypography';
import MuiSelect from './MuiSelect';
import MuiTable from './MuiTable';
import MuiMenu from './MuiMenu';
import MuiMenuItem from './MuiMenuItem';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiCssBaseline from './MuiCssBaseline';
import MuiToggleButtonGroup from './MuiToggleButtonGroup';
import MuiToggleButton from './MuiToggleButton';
import MuiRadio from './MuiRadio';
import MuiTooltip from './MuiTooltip';
import MuiPaper from './MuiPaper';
import MuiAutocomplete from './MuiAutocomplete';
import MuiPaginationItem from './MuiPaginationItem';
import MuiSwitch from './MuiSwitch';

const overrides = {
  components: {
    MuiButton,
    MuiLink,
    MuiInput,
    MuiTextField,
    MuiFormControl,
    MuiInputLabel,
    MuiFormHelperText,
    MuiDialog,
    MuiDialogTitle,
    MuiIconButton,
    MuiCheckbox,
    MuiFormLabel,
    MuiCalendarPicker,
    MuiTableContainer,
    MuiTableRow,
    MuiTableCell,
    MuiTableSortLabel,
    MuiTablePagination,
    MuiFormControlLabel,
    MuiTypography,
    MuiMenu,
    MuiMenuItem,
    MuiBreadcrumbs,
    MuiSelect,
    MuiTable,
    MuiCssBaseline,
    MuiToggleButtonGroup,
    MuiToggleButton,
    MuiRadio,
    MuiTooltip,
    MuiPaper,
    MuiAutocomplete,
    MuiPaginationItem,
    MuiSwitch,
  },
};

export default overrides;
