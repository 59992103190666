import { useState, useEffect } from 'react';
import { Button, CircularProgress, Typography, Stack, Divider, Alert } from '@mui/material';
import { head } from 'ramda';

import { useModals, useRouter } from 'src/hooks';
import appRoutes from 'src/routes/appRoutes';
import useCertificates from 'src/hooks/talents/useCertificates';
import { ReactComponent as PlusIcon } from 'src/assets/images/plusIcon.svg';
import type CertificateType from 'src/types/resources/CertificateType';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import Box from 'src/components/Box';

import Entry from './components/Entry';
import EditableBox from './components/EditableBox';
import styles from './styles';

interface CertificateProps {
  onClose?: () => void;
}

const Certificate: React.FC<CertificateProps> = props => {
  const [errors, setErrors] = useState<string | null>(null);
  const { certificates, isLoadCertificatesFinished, loadCertificates, deleteCertificate } = useCertificates();
  const { showModal, hideModal } = useModals();
  const { pathname } = useRouter();

  const handleDeleteCertificate = (certificate: CertificateType) => async () => {
    try {
      await deleteCertificate({ id: certificate.id }).unwrap();
      loadCertificates();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setErrors(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };

  const handleClose = () => {
    const { onClose } = props;
    hideModal();
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (certificates.length === 0 && pathname === appRoutes.talent.certificates()) {
      loadCertificates();
    }
  }, []);

  if (!isLoadCertificatesFinished) {
    return (
      <Box sx={styles.box}>
        <CircularProgress color="primary" size={50} />
      </Box>
    );
  }

  return (
    <Box sx={styles.box}>
      {errors && <Alert severity="error">{errors}</Alert>}
      <Box sx={styles.header}>
        <Typography variant="h3" sx={styles.heading}>
          Resume, Certifications and More
        </Typography>
        <Divider />
      </Box>
      <Box sx={styles.body}>
        <Stack spacing={1} sx={styles.certificateList}>
          {certificates.map(certificate => (
            <EditableBox
              key={certificate.id}
              handleEdit={() => {
                showModal({
                  type: ModalType.TalentCertificateForm,
                  modalLayout: ModalLayout.talentModals,
                  props: { certificate },
                });
              }}
              handleDelete={handleDeleteCertificate(certificate)}
            >
              <Entry key={certificate.id} certificate={certificate} />
            </EditableBox>
          ))}
        </Stack>

        <Button
          variant="text"
          startIcon={<PlusIcon />}
          onClick={() => {
            showModal({
              type: ModalType.TalentCertificateForm,
              modalLayout: ModalLayout.talentModals,
            });
          }}
        >
          Add Resume, Certifications and More
        </Button>
      </Box>
      <Box sx={styles.footer}>
        <Button variant="outlined" sx={styles.button} onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default Certificate;
