import {
  KnowledgeCenterVideosSliceStateType,
  KnowledgeCenterVideosSliceActionsType,
  loadTalentsKnowledgeCenterVideos,
  loadManagerKnowledgeCenterVideos,
  resetKnowledgeCenterVideos,
} from 'src/store/knowledgeCenterVideosSlice';
import { useAppDispatch, useAppSelector } from 'src/hooks/useRedux';
import getFetchStatus from 'src/utils/fetchStatus';
import { IndexParams } from 'src/repositories/talents/KnowledgeCenterVideosRepository';

type UseEngagementTimeSheetsType = Pick<KnowledgeCenterVideosSliceStateType, 'meta' | 'videos'> &
  KnowledgeCenterVideosSliceActionsType & {
    isKnowledgeCenterVideosLoadFinished: boolean;
  };

const useKnowledgeCenterFAQ = (): UseEngagementTimeSheetsType => {
  const dispatch = useAppDispatch();
  const {
    meta,
    videos,
    index: { fetchStatus: knowledgeCenterFAQIndexStatus },
  } = useAppSelector(state => state.knowledgeCenterVideos);

  const isKnowledgeCenterVideosLoadFinished = getFetchStatus(knowledgeCenterFAQIndexStatus).isFinished;

  return {
    meta,
    videos,
    isKnowledgeCenterVideosLoadFinished,
    loadTalentsKnowledgeCenterVideos: (params?: IndexParams) => dispatch(loadTalentsKnowledgeCenterVideos(params)),
    loadManagerKnowledgeCenterVideos: (params?: IndexParams) => dispatch(loadManagerKnowledgeCenterVideos(params)),
    resetKnowledgeCenterVideos: () => dispatch(resetKnowledgeCenterVideos()),
  };
};

export default useKnowledgeCenterFAQ;
