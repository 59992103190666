import { Alert, IconButton, Typography } from '@mui/material';
import { head } from 'ramda';
import { useState } from 'react';

import useModals from 'src/hooks/useModals';
import { ModalLayout, ModalType } from 'src/enums/ModalType';
import { isAxiosError, isUnprocessedEntityError } from 'src/utils/responseErrors';
import { ReactComponent as EditIcon } from 'src/assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/images/deleteIcon.svg';
import { ExternalLink } from 'src/types/resources/ExternalLink';
import useExternalLinks from 'src/hooks/talents/useExternalLinks';
import Box from 'src/components/Box';
import TypographyNoWrap from 'src/components/TypographyNoWrap';

import styles from './styles';

type ItemProps = {
  externalLink: ExternalLink;
};

const Item: React.FC<ItemProps> = props => {
  const { externalLink } = props;
  const { id, link, description } = externalLink;

  const { showModal } = useModals();
  const [errors, setErrors] = useState<string>(null);
  const { loadExternalLinks, deleteExternalLink } = useExternalLinks();

  const handleDelete = async () => {
    try {
      await deleteExternalLink({ id });
      loadExternalLinks();
    } catch (error: unknown) {
      if (isAxiosError(error) && isUnprocessedEntityError(error)) {
        setErrors(head(error.response.data.errors.nonFieldErrors));
      }
    }
  };
  return (
    <Box sx={styles.wrapper}>
      {errors && <Alert severity="error">{errors}</Alert>}
      <Box sx={styles.description}>
        <TypographyNoWrap variant="h5">{link}</TypographyNoWrap>
        <Typography variant="body1">{description}</Typography>
      </Box>
      <Box sx={styles.buttons}>
        <IconButton
          sx={styles.button}
          onClick={() =>
            showModal({
              type: ModalType.TalentExternalLinksForm,
              modalLayout: ModalLayout.talentModals,
              props: { externalLink },
            })
          }
        >
          <EditIcon />
        </IconButton>
        <IconButton sx={styles.button} onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Item;
