import { makeRoutes } from 'src/utils/routes';

const appRoutes = makeRoutes({
  rootPath: () => '/',
  accountPath: () => '/account',
  page404Path: () => '/page404',
  engagements: () => '/engagements',
  opportunities: () => `/opportunities`,
  createOpportunity: () => `/new-opportunity`,
  opportunityDetails: (opportunityId: ID) => `/opportunities/${opportunityId}`,
  editOpportunity: (opportunityId: ID) => `/opportunity/${opportunityId}/edit`,
  messengerPath: () => '/messenger',
  knowledgeCenterVideosPath: () => `/knowledge-center/video-tutorials`,
  public: {
    signUpPath: () => '/sign-up',
  },
  superAdmin: {
    dashboardPath: () => '/dashboard',
    talentsPath: () => '/talents',
    talentPath: (talentId: ID) => `/talents/${talentId}`,
    organizationsPath: () => '/organizations',
    organizationPath: (organizationId: ID) => `/organizations/${organizationId}`,
    accountingPath: () => '/accounting',
    messengerPath: () => '/messenger',
    internalPath: () => '/internal',
    backgroundJobs: () => '/background-jobs',
  },
  organizationAdmin: {
    groupsPath: () => '/groups',
    groupPath: (subgroupId: ID) => `/groups/${subgroupId}`,
    searchPath: () => '/search',
    engagementsPath: () => '/engagements',
    engagementPath: (engagementId: ID) => `/engagements/${engagementId}`,
    newEngagement: () => `/new-engagement`,
    timeTrackingPath: () => '/time-tracking',
    historicalAdjustmentsPath: () => `/time-tracking/historical-adjustments`,
    documentLibraryPath: () => '/document-library',
    messengerPath: () => '/messenger',
    talentsPath: () => '/talents',
    talentPath: (talentId: ID) => `/talents/${talentId}`,
    organizationPath: () => '/organization',
    knowledgeCenterPath: () => `/knowledge-center`,
    knowledgeCenterFAQPath: () => `/knowledge-center/faq`,
    knowledgeCenterVideosPath: () => `/knowledge-center/video-tutorials`,
  },
  manager: {
    groupsPath: () => '/groups',
    groupPath: (subgroupId: ID) => `/groups/${subgroupId}`,
    searchPath: () => '/search',
    engagementsPath: () => '/engagements',
    engagementPath: (engagementId: ID) => `/engagements/${engagementId}`,
    newEngagement: () => `/new-engagement`,
    timeTrackingPath: () => '/time-tracking',
    historicalAdjustmentsPath: () => `/time-tracking/historical-adjustments`,
    messengerPath: () => '/messenger',
    talentsPath: () => '/talents',
    talentPath: (talentId: ID) => `/talents/${talentId}`,
    knowledgeCenterPath: () => `/knowledge-center`,
    knowledgeCenterFAQPath: () => `/knowledge-center/faq`,
    knowledgeCenterVideosPath: () => `/knowledge-center/video-tutorials`,
  },
  organization: {
    extendEngagement: (engagementId: ID) => `/engagements/extend/${engagementId}`,
    duplicateEngagement: (engagementId: ID) => `/engagements/duplicate/${engagementId}`,
    draftEngagement: (engagementId: ID) => `/engagements/draft/${engagementId}`,
  },
  talent: {
    welcomePath: () => '/welcome',
    talentProfilePath: () => '/profile',
    dashboardPath: () => '/dashboard',
    engagementPath: (engagementId: ID) => `/engagements/${engagementId}`,
    timeTrackingPath: () => '/time-tracking',
    payrollHistory: () => '/time-tracking/payroll-history',
    historicalAdjustmentsPath: () => `/time-tracking/historical-adjustments`,
    organizationsPath: () => '/organizations',
    messengerPath: () => '/messenger',
    availabilitiesPath: () => '/availabilities',
    newAvailabilityPath: () => '/availabilities/new',
    editAvailabilityPath: (id: ID) => `/availabilities/${id}/edit`,
    summary: () => '/summary',
    workExperience: () => '/work-experience',
    externalLinks: () => '/external-links',
    certificates: () => '/certificates',
    documents: () => '/documents',
    specializations: () => '/specializations',
    knowledgeCenterPath: () => `/knowledge-center`,
    knowledgeCenterFAQPath: () => `/knowledge-center/faq`,
    knowledgeCenterVideosPath: () => `/knowledge-center/video-tutorials`,
    opportunities: () => `/opportunities`,
    applications: () => `/applications`,
  },
});

export default appRoutes;
